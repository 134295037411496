<template>
  <Page :data="pageData" :is-child="_isChild" @refresh="refresh" />
</template>
<script>

import CMSPage from './CMSPage'

export default {
  extends: CMSPage
}
</script>

<style>

</style>
